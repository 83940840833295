import React from "react";

type SVGProps = { path?: string, className?: string };

const SVG: React.FC<SVGProps> = (props: SVGProps): JSX.Element => (
  <svg
    className={`w-4 h-4 abosolute text-gray-500 stroke-current ${props.className}`}
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d={props.path}
    />
  </svg>
);

export default SVG;
