import React from 'react';
import { Link } from 'gatsby';
import 'moment/locale/it';
import Moment from 'react-moment';

import { BadgeSecondary, BadgeLight } from './badges';
import SVG from './svg';
import HTMLParser from './html-parser';

type PostItemProps = {
  image?: string;
  slug?: string;
  post: any;
};

const PostItem: React.FC<PostItemProps> = (
  props: PostItemProps
): JSX.Element => {
  return (
    <Link className='h-full' to={`${props.post.uri}`}>
      <article className='flex flex-col flex-grow space-y-4 bg-transparent cursor-pointer'>
        <div className='flex flex-row items-center justify-between'>
          <span className='mt-2 md:mt-0 text-xs text-gray-800 font-semibold uppercase space-x-1'>
            {props.post.categories?.nodes.map((cat) => (
              <BadgeLight category={cat.name} />
            ))}
            <span className='text-xs text-gray-600 capitalize font-normal'></span>
          </span>

        </div>
        <div className='flex flex-col flex-grow'>
          <div className='mb-3 flex flex-1 flex-col-reverse md:flex-row md:justify-between items-start md:items-center'>
            <h3 className='mt-2 md:mt-0 text-gray-700 text-xl font-bold'>
              {props.post.title}
            </h3>
            <span className='inline-flex items-center space-x-1 text-xs'>
              <SVG
                className='w-2 h-2 stroke-current text-gray-600'
                path='M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z'
              ></SVG>
              <Moment locale='it' format='DD MMMM YYYY'>
                {props.post.date}
              </Moment>
            </span>
          </div>
          {props.post.excerpt ? <p
            key={props.post.title}
            className='mb-6 text-base prose text-gray-500'
          >
            <HTMLParser>{props.post.excerpt}</HTMLParser>
          </p> : <></>}

          <button className='w-20 p-0 rounded inline-flex flex-row flex-grow-1 justify-between items-center space-x-1 text-sm text-orange-600 font-semibold text-left outline-none'>
            <span>Leggi</span>
            <SVG
              className='w-3 h-3 text-orange-600 stroke-current'
              path='M17 8l4 4m0 0l-4 4m4-4H3'
            ></SVG>
          </button>
        </div>
      </article>
    </Link>
  );
};

export default PostItem;

export const PostItemV2: React.FC<PostItemProps> = (props: PostItemProps) => {
  return (
    <Link className='h-full' to={`${props.post.slug}`}>
      <article className='flex flex-col overflow-hidden'>
        <div className='flex-shrink-0'>
          <img
            className='h-48 w-full object-cover'
            src={
              props.post.image ||
              'https://images.unsplash.com/photo-1547586696-ea22b4d4235d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1679&q=80'
            }
            alt=''
          />
          {/* <div className='h-2 bg-gradient-to-r from-green-400 to-blue-500'></div> */}
        </div>
        <div className='flex-1 bg-white p-6 flex flex-col justify-between'>
          <div className='flex-1'>
            <p className='text-sm font-medium text-orange-600'>
              {props.post.categories?.nodes.map((cat) => (
                <a href='#' className='hover:underline'>
                  {cat.name}
                </a>
              ))}
            </p>
            <a href='#' className='flex flex-col mt-2'>
              <p className='text-xl font-semibold text-gray-900'>
                {props.post.title}
              </p>
              {props.post.excerpt ? <p
                key={props.post.title}
                className='mt-3 text-base text-gray-500'
              >
                <HTMLParser>{props.post.excerpt}</HTMLParser>
              </p> : <></>}
            </a>
          </div>
          <div className='mt-6 flex items-center'>
            <div className='flex-shrink-0'>
              <a href='#'>
                <span className='sr-only'>{'Davide Sicignani'}</span>
                <img
                  className='h-10 w-10 rounded-full'
                  src={
                    props.post.image ||
                    'https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                  }
                  alt=''
                />
              </a>
            </div>
            <div className='ml-3'>
              <p className='text-sm font-medium text-gray-900'>
                <a href='#' className='hover:underline'>
                  {'Davide Sicignani'}
                </a>
              </p>
              <div className='flex space-x-1 text-sm text-gray-500'>
                <time dateTime='2020-03-10'>{'Mar 10, 2020'}</time>
                <span aria-hidden='true'>&middot;</span>
                <span>4 min read</span>
              </div>
            </div>
          </div>
        </div>
      </article>
    </Link>
  );
};
