import React from "react";

// TODO Lasciare solamente text e togliere category
type BadgeProps = {
  className?: string;
  children?: any;
  text?: any;
  category?: any;
};

export const BadgeTrasparent = (props: BadgeProps) => (
  <span
    className={`rounded-full bg-transparent font-medium uppercase space-x-1 ${props.className}`}
  >
    {props.text}
  </span>
);

export const BadgePrimary = (props: BadgeProps) => (
  <span className="px-3 py-1 rounded-full bg-gray-100 text-gray-800 font-semibold uppercase space-x-1">
    {props.category || props.text}
  </span>
);


export const BadgeLight = (props: BadgeProps) => (
  <span
    className={`px-3 py-1 rounded-full bg-gray-100 text-xs text-gray-700 font-semibold uppercase space-x-1`}
  >
    {props.category || props.text || props.children}
  </span>
);

export const BadgeSecondary = (props: BadgeProps) => (
  <span
    className={`px-3 py-1 rounded-full bg-gray-300 text-xs text-gray-800 font-semibold uppercase space-x-1`}
  >
    {props.category || props.text || props.children}
  </span>
);
