import { Link } from 'gatsby';
import React from 'react';
import BlogPost from './blog-post';
import PostItem from './post-item';

type BlogIndexSectionProps = {
  className?: string;
  title?: string;
  totalCount?: number;
  slug?: string;
  posts: any[];
  children?: any;
};

const BlogIndexSection: React.FC<BlogIndexSectionProps> = (
  props: BlogIndexSectionProps
): JSX.Element => (
  <div className={`space-y-2 ${props.className}`}>
    <div className='flex flex-col space-y-20'>
      {props.posts?.map((post) => (
        <PostItem slug={props.slug} post={post} />
      ))}
    </div>
  </div>
);

export default BlogIndexSection;
