import React from "react";
import { graphql } from "gatsby";

import environment from "../config/environment";
import Layout from "../components/layout";
import { BadgeTrasparent } from "../components/badges";
import BlogIndexSection from "../components/blog-index-section";

const Tag = (props) => {
  const {
    data: {
      tag,
      posts: { edges: posts, totalCount: totalPosts },
      caseStudies: { edges: caseStudies, totalCount: totalCaseStudies },
    },
    pageContext: { url, tagName },
  } = props;

  const allPosts = posts.map(({ node }) => node);
  const allCaseStudies = caseStudies.map(({ node }) => node);

  return (
    <Layout
      seo={{
        title: `Articoli e casi studio su ${tagName} - Pricyo`,
        metaDesc: tag?.description,
        opengraphUrl: url,
        canonical: `${environment.HOST}${url}`,
      }}
    >
      <h1 className="my-12">
        Abbiamo{" "}
        <span className="underline font-medium">{totalPosts} Articoli</span> e{" "}
        <span className="underline font-medium">
          {totalCaseStudies} Case Study
        </span>{" "}
        che parlano di{" "}
        <BadgeTrasparent
          className="text-gray-500"
          text={`#${tagName}`}
        ></BadgeTrasparent>
      </h1>
      <BlogIndexSection
        posts={[...allPosts, ...allCaseStudies]}
      ></BlogIndexSection>
    </Layout>
  );
};

export const query = graphql`
  query tag($tagId: String!) {
    tag: wpTag(id: { eq: $tagId }) {
      id
      name
      description
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
      }
    }
    posts: allWpPost(
      filter: { tags: { nodes: { elemMatch: { id: { eq: $tagId } } } } }
    ) {
      edges {
        node {
          id
          uri
          title
          excerpt
          date
          categories {
            nodes {
              id
              name
            }
          }
          tags {
            nodes {
              id
              name
            }
          }
          author {
            node {
              avatar {
                url
              }
              name
            }
          }
        }
      }
      totalCount
    }
    caseStudies: allWpCaseStudy(
      filter: { tags: { nodes: { elemMatch: { id: { eq: $tagId } } } } }
    ) {
      edges {
        node {
          id
          uri
          title
          excerpt
          date
          categories {
            nodes {
              id
              name
            }
          }
          tags {
            nodes {
              id
              name
            }
          }
          author {
            node {
              avatar {
                url
              }
              name
            }
          }
        }
      }
      totalCount
    }
  }
`;

export default Tag;
